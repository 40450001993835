// Arquivo criado: 13/07/2023 às 18:33
import React from 'react'
import * as S from './styles'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import { type IWorkStatus } from '../../interfaces/IWorkStatus'
import { type INotification } from '../../interfaces/INotification'
import CircularProgress from '@mui/material/CircularProgress'
import dayjs from 'dayjs'

interface ITableInfos extends IWorkStatus {
  count: number
}

export const Home = (): JSX.Element => {

  const navigate = useNavigate()
  const userId = useSelector((state: RootState) => state.user.perfil?.id)
  const needInfos = useSelector((state: RootState) => state.user.perfil?.needInfos)
  const event = useSelector((state: RootState) => state.event.data)
  const dispatch = useDispatch()

  const [tableInfos, setTableInfos] = React.useState<ITableInfos[]>([])
  const [notifications, setNotifications] = React.useState<INotification[]>([])
  const latestNotifications = notifications.slice(0, 3)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const getData = async (): Promise<void> => {

      document.title = 'Submissão de trabalhos - Dashboard'

      if (!event) return
      if (!userId) return

      setLoading(true)
      const response = await backEnd('GET', `works/event/${event.id}/user/${userId}?count=true`)
      const resNotifications = await backEnd('GET', `notifications/user/${userId}`)
      // const response2 = await backEnd('GET', `notifications/user/${userId}?latest=true`)
      setLoading(false)
      const data: INotification[] = resNotifications.data

      if (!response.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: response.msg
        }))
      } else {
        setTableInfos(response.data)
      }

      if (!resNotifications.ok) {
        dispatch(setOpenGlobalMessageAction({
          message: resNotifications.msg
        }))
      } else {
        setNotifications(data)
      }

    }
    void getData()
  }, [userId, event, dispatch])

  const handleClick = (): void => {
    navigate('/perfil')
  }

  const handleNotificationClick = (): void => {
    navigate('/notificacoes')
  }

  const handleRegisterClick = (): void => {
    navigate('/perfil')
  }

  const handleSubmissionClick = (): void => {
    navigate('/submissao')
  }

  return (
    <S.Container>
      {
        needInfos
          ? (
            <S.AskRegisterContainer>
              <h3>Atualize seu perfil para confirmar sua inscrição!</h3>
              <Button variant="outlined" onClick={handleClick}>Continuar para Perfil</Button>
            </S.AskRegisterContainer>
          )
          : (
            <S.ScreenContainer>
              <S.RowContainer>
                {event && <p>Este evento possui <span>{event._count.works}</span> trabalhos submetidos</p>}
              </S.RowContainer>
              <S.RowContainer className='worksInfo'>
                <h2>Trabalhos Enviados</h2>
              </S.RowContainer>
              <S.RowContainer className='bodyContainer'>
                {
                  loading
                    ? (
                      <S.LoadingContainer>
                        <CircularProgress />
                      </S.LoadingContainer>
                    )
                    : (
                      <S.ColumnContainer width={70}>
                        <>
                          {
                            tableInfos.length > 0
                              ? (
                                <S.TableContainer>
                                  <S.RowContainer className='tableRow tableFirstRow'>
                                    {
                                      tableInfos.map((item, index) => (
                                        <S.RowContainer className='tableData'/*  justifycontent='flex-start'  */key={index}>
                                          <S.ColumnContainer className='tableColumn'>
                                            <h5>{item.label}</h5>
                                            <p>{item.count}</p>

                                          </S.ColumnContainer>
                                        </S.RowContainer>
                                      ))
                                    }
                                  </S.RowContainer>
                                </S.TableContainer>
                              )
                              : (
                                <>
                                  <p>Você ainda não enviou trabalhos...</p>
                                  <S.RowContainer>
                                    <Button variant='outlined' onClick={handleSubmissionClick}>Ir para Submissão de Trabalhos</Button>
                                  </S.RowContainer>
                                </>
                              )
                          }
                        </>

                      </S.ColumnContainer>
                    )
                }

                <S.ColumnContainer width={30} className='rightColumn'>
                  <h2>Notificações recentes</h2>

                  {
                    latestNotifications.map((item, index) => (
                      <S.NotificationContainer key={index}>
                        <h4 style={item.read ? { color: '#777' } : { color: '#222' }}>{item.title}</h4>
                        <p>{dayjs(item.createdAt).format('DD/MM/YYYY')}</p>
                      </S.NotificationContainer>
                    ))
                  }

                  <S.RowContainer>
                    <Button className='button' variant='outlined' onClick={handleNotificationClick}>Abrir Notificações</Button>
                  </S.RowContainer>
                  <S.RowContainer>
                    <S.LinkStyle onClick={handleRegisterClick}>Editar Dados de Inscrição</S.LinkStyle>
                  </S.RowContainer>
                </S.ColumnContainer>
              </S.RowContainer>
            </S.ScreenContainer>
          )
      }

    </S.Container>
  )
}
