// Arquivo criado: 06/10/2023 às 14:38
import React from 'react'
import * as S from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState } from '../../redux/store'
import { backEnd } from '../../utils/backend.util'
import { setOpenGlobalMessageAction } from '../../redux/actions/globalMessage.action'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { type IBoard } from '../../interfaces/IBoard'
import { type IManagement } from '../../interfaces/IManagement'
import { type IWorkCategory } from '../../interfaces/IWorkCategory'
import { type IWorkModel } from '../../interfaces/IWorkModel'
import CircularProgress from '@mui/material/CircularProgress'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Button from '@mui/material/Button'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

interface IMacrotheme {
  id: number
  label: string
  archived: boolean
}

interface IWorkQuestion {
  id: number
  question: string
  title: string
  weight: number
  modelIds: number[]
  archived: boolean
}

export const CreateEditItems = (): JSX.Element => {

  const event = useSelector((state: RootState) => state.event.data)
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(false)
  const [get, setGet] = React.useState(false)
  // diretorias
  const [boards, setBoards] = React.useState<IBoard[]>([]) // get de todas as diretorias
  const [board, setBoard] = React.useState<IBoard | null>(null) // para colocar a diretoria nova
  const [shownBoard, setShownBoard] = React.useState('') // label da diretoria selecionada
  const [boardManagements, setBoardManagements] = React.useState<IManagement[]>([]) // gerências da diretoria selecionada
  const [shownBoardManagements, setShownBoardManagements] = React.useState('')
  const [filteredBoards, setFilteredBoards] = React.useState<IBoard[]>([])
  // gerências
  const [management, setManagement] = React.useState<IManagement | null>(null)
  const [shownManagement, setShownManagement] = React.useState('')
  const [allManagements, setAllManagements] = React.useState<IManagement[]>([])
  const [shownChosenManagement, setShownChosenManagement] = React.useState('')
  const [filteredManagements, setFilteredManagements] = React.useState<IManagement[]>([])
  // macrotema
  const [macrothemes, setMacrothemes] = React.useState<IMacrotheme[]>([])
  const [macrotheme, setMacrotheme] = React.useState<IMacrotheme | null>(null)
  const [shownMacrotheme, setShownMacrotheme] = React.useState('')
  const [macrothemeCategories, setMacrothemeCategories] = React.useState<IWorkCategory[]>([])
  const [shownMacrothemeCategories, setShownMacrothemeCategories] = React.useState('')
  const [filteredMacrothemes, setFilteredMacrothemes] = React.useState<IMacrotheme[]>([])
  // categoria
  const [category, setCategory] = React.useState<IWorkCategory | null>(null)
  const [shownCategory, setShownCategory] = React.useState('')
  const [categoryModels, setCategoryModels] = React.useState<IWorkModel[]>([])
  const [shownCategoryModels, setShownCategoryModels] = React.useState('')
  const [allCategories, setAllCategories] = React.useState<IWorkCategory[]>([])
  const [shownChosenCategory, setShownChosenCategory] = React.useState('')
  const [filteredCategories, setFilteredCategories] = React.useState<IWorkCategory[]>([])
  // modelos
  const [model, setModel] = React.useState<IWorkModel | null>(null)
  const [shownModel, setShownModel] = React.useState('')
  const [allModels, setAllModels] = React.useState<IWorkModel[]>([])
  const [shownChosenModel, setShownChosenModel] = React.useState('')
  const [filteredModels, setFilteredModels] = React.useState<IWorkModel[]>([])
  // questões
  const [question, setQuestion] = React.useState<IWorkQuestion | null>(null)
  const [shownQuestion, setShownQuestion] = React.useState('')
  const [questionModels, setQuestionModels] = React.useState<IWorkModel[]>([])
  const [shownQuestionModels, setShownQuestionModels] = React.useState('')
  const [shownChosenQuestionModel, setShownChosenQuestionModel] = React.useState('')
  // nova interpretação
  const [allQuestions, setAllQuestions] = React.useState<IWorkQuestion[]>([])
  const [modelQuestions, setModelQuestions] = React.useState<IWorkQuestion[]>([])
  const [selectedModelForQuestion, setSelectedModelForQuestion] = React.useState(0)
  const [createNewQuestion, setCreateNewQuestion] = React.useState(false)
  const [editQuestion, setEditQuestion] = React.useState(false)
  // controle de item
  const [control, setControl] = React.useState(0)
  const [controlEdit, setControlEdit] = React.useState(0)
  const [controlDelete, setControlDelete] = React.useState(0)
  // add itens
  const [newBoard, setNewBoard] = React.useState('')
  const [newManagement, setNewManagement] = React.useState('')
  const [newCategory, setNewCategory] = React.useState('')
  const [newModel, setNewModel] = React.useState('')
  const [newMacrotheme, setNewMacrotheme] = React.useState('')
  const [newQuestion, setNewQuestion] = React.useState('')
  const [newQuestionDescription, setNewQuestionDescription] = React.useState('')
  const [newQuestionWeight, setNewQuestionWeight] = React.useState(1)
  // opções de delete
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [deleteItem, setDeleteItem] = React.useState<IBoard | IManagement | IWorkCategory | IWorkModel | IMacrotheme | IWorkQuestion | null>(null)
  // modal
  const [openNoticeModal, setOpenNoticeModal] = React.useState(false)
  const [releaseEdtion, setReleaseEdtion] = React.useState(false)

  React.useEffect(() => {

    const getData = async (): Promise<void> => {

      document.title = 'Distribuição de trabalhos'
      if (!event) return

      setLoading(true)
      const resBoards = await backEnd('GET', 'boards')
      const resCategories = await backEnd('GET', 'works/categories')
      setLoading(false)

      if (!resBoards.ok || !resCategories.ok) {

        const message = resBoards.ok ? resCategories.msg : resBoards.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setBoards(resBoards.data)
      setFilteredBoards(resBoards.data.filter((item: IBoard) => !item.archived))
      setAllCategories(resCategories.data)
      setFilteredCategories(resCategories.data.filter((item: IWorkCategory) => !item.archived))
      setBoardManagements([])

      if (get) {
        setGet(false)
      }
    }

    void getData()
  }, [dispatch, event, get])

  React.useEffect(() => {

    const getData = async (): Promise<void> => {

      if (!event) return

      setLoading(true)
      const resMacrothemes = await backEnd('GET', 'work-macrothemes')
      setLoading(false)

      if (!resMacrothemes.ok) {

        const message = 'Erro ao buscar os macrotemas'

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setMacrothemes(resMacrothemes.data)
      setFilteredMacrothemes(resMacrothemes.data.filter((item: IMacrotheme) => !item.archived))
      if (get) {
        setGet(false)
      }
    }

    void getData()
  }, [dispatch, event, get])

  React.useEffect(() => {

    const getData = async (): Promise<void> => {

      if (!event) return

      setLoading(true)
      const resAllManagements = await backEnd('GET', 'managements')
      const resAllModels = await backEnd('GET', 'works/models')
      setLoading(false)

      if (!resAllManagements.ok || !resAllModels.ok) {

        const message = resAllManagements.ok ? resAllModels.msg : resAllManagements.msg

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setAllManagements(resAllManagements.data)
      setFilteredManagements(resAllManagements.data.filter((item: IManagement) => !item.archived))
      setAllModels(resAllModels.data)
      setFilteredModels(resAllModels.data.filter((item: IWorkModel) => !item.archived))

      if (get) {
        setGet(false)
      }
    }

    void getData()
  }, [dispatch, event, get])

  React.useEffect(() => {

    const getData = async (): Promise<void> => {

      if (!event) return

      setLoading(true)
      const resAllQuestions = await backEnd('GET', 'works/models/questions')
      setLoading(false)

      if (!resAllQuestions.ok) {

        const message = 'Erro ao buscar as questões'

        dispatch(setOpenGlobalMessageAction({ message }))
        return
      }

      setAllQuestions(resAllQuestions.data)

      if (get) {
        setGet(false)
      }
    }

    void getData()
  }, [dispatch, event, get])

  React.useEffect(() => {
    setModelQuestions(allQuestions.filter(item => item.modelIds.includes(selectedModelForQuestion)).sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)))
    const modelSelected: IWorkModel | undefined = filteredModels.find(item => item.id === selectedModelForQuestion)
    if (modelSelected) {
      setShownModel(modelSelected.label)
      setModel(modelSelected)
    }
  }, [modelQuestions, allQuestions, filteredModels, selectedModelForQuestion])

  const clearPageVariables = (): void => {
    setControl(0)
    setControlEdit(0)

    setBoard(null)
    setShownBoard('')
    setNewBoard('')
    setBoardManagements([])
    setShownBoardManagements('')

    setManagement(null)
    setShownManagement('')
    setNewManagement('')
    setShownChosenManagement('')

    setCategory(null)
    setShownCategory('')
    setNewCategory('')
    setCategoryModels([])
    setShownCategoryModels('')

    setModel(null)
    setShownModel('')
    setControl(0)
    setControlEdit(0)
    setNewModel('')
    setShownChosenModel('')

    setMacrotheme(null)
    setShownMacrotheme('')
    setControl(0)
    setControlEdit(0)
    setNewMacrotheme('')
    setShownChosenCategory('')
    setMacrothemeCategories([])

    setNewQuestion('')
    setNewQuestionDescription('')
    setNewQuestionWeight(1)
    setQuestion(null)
    setShownQuestion('')
    setShownQuestionModels('')
    setShownChosenQuestionModel('')
    setQuestionModels([])

    setCreateNewQuestion(false)
    setEditQuestion(false)
    setSelectedModelForQuestion(0)

    setDeleteItem(null)
    setControlDelete(0)

    setReleaseEdtion(false)
  }

  const clearQuestionVariables = (): void => {

    setNewQuestion('')
    setNewQuestionDescription('')
    setNewQuestionWeight(1)
    setQuestion(null)
    setShownQuestion('')
    setShownQuestionModels('')
    setShownChosenQuestionModel('')
    setQuestionModels([])

    setCreateNewQuestion(false)
    setEditQuestion(false)
    // setSelectedModelForQuestion(0)
  }

  const handleSelectBoard = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    clearPageVariables()
    const id = Number(e.target.value)
    const _board = boards.find(item => item.id === id)

    if (!_board) return
    setBoard(_board)
    const response = await backEnd('GET', `managements/board/${_board.id}`)
    setShownBoard(_board.label)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setBoardManagements(response.data)
    setReleaseEdtion(false)
  }

  const handleSelectManagement = (e: React.ChangeEvent<HTMLInputElement>): void => {
    clearPageVariables()
    const id = Number(e.target.value)
    const _management = allManagements.find(item => item.id === id)
    if (!_management) return
    setManagement(_management)
    setShownManagement(_management.label)
    setReleaseEdtion(false)
  }

  const handleSelectMacrotheme = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    clearPageVariables()
    const id = Number(e.target.value)
    const _macrotheme = macrothemes.find(item => item.id === id)

    if (!_macrotheme) return
    setMacrotheme(_macrotheme)
    const response = await backEnd('GET', `works/categories/macrotheme/${_macrotheme.id}`)
    setShownMacrotheme(_macrotheme.label)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }
    setMacrothemeCategories(response.data)
    setReleaseEdtion(false)
  }

  const handleSelectCategory = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    clearPageVariables()
    const id = Number(e.target.value)
    const _category = allCategories.find(item => item.id === id)

    if (!_category) return
    setCategory(_category)
    const response = await backEnd('GET', `works/models/category/${_category.id}`)
    setShownCategory(_category.label)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    setCategoryModels(response.data)
    setReleaseEdtion(false)
  }

  const handleSelectModel = (e: React.ChangeEvent<HTMLInputElement>): void => {
    clearPageVariables()
    const id = Number(e.target.value)
    const _model = allModels.find(item => item.id === id)
    setModelQuestions(allQuestions.filter(item => item.modelIds.includes(id)).sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)))
    if (!_model) return
    setModel(_model)
    setShownModel(_model.label)
    setSelectedModelForQuestion(id)
    setReleaseEdtion(false)
  }

  const handleSelectQuestion = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // clearPageVariables()
    const id = Number(e.target.value)
    const _question = modelQuestions.find(item => item.id === id)
    if (!_question) return
    setQuestion(_question)
    setShownQuestion(_question.title)
    const _questionModels: IWorkModel[] = []
    for (let i = 0; i < _question.modelIds.length; i++) {
      const model = allModels.find(item => item.id === _question.modelIds[i])
      if (model) {
        _questionModels.push(model)
      }
    }
    setQuestionModels(_questionModels)
  }

  const handleEditBoard = (item: IBoard): void => {
    setNewBoard(item.label)
    setControlEdit(1)
    setControl(1)
    setReleaseEdtion(true)
  }

  const handleAddBoard = (): void => {
    clearPageVariables()
    setControl(1)
    setControlEdit(0)
    setReleaseEdtion(true)
  }

  const handleNewBoard = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewBoard(event.target.value)
  }

  const handleEditableBoard = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewBoard(event.target.value)
  }

  const handleEditManagement = (item: IManagement): void => {
    setNewManagement(item.label)
    setControlEdit(2)
    setControl(2)
    setReleaseEdtion(true)
  }

  const handleAddManagement = (): void => {
    clearPageVariables()
    setControl(2)
    setControlEdit(0)
    setReleaseEdtion(true)
  }

  const handleNewManagement = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewManagement(event.target.value)
  }

  const handleEditableManagement = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewManagement(event.target.value)
  }

  const handleExistingManagement = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const items = allManagements.find(item => item.id === id)
    if (items && boardManagements.find(item => item.id === items.id)) {
      setBoardManagements(
        boardManagements.filter(item => item.id !== items.id)
      )
      setShownBoardManagements(items.label)
    } else if (items && !boardManagements.find(item => item.id === items.id)) {
      setBoardManagements(
        [
          ...boardManagements,
          { id: items.id, label: items.label, archived: items.archived }
        ]
      )
      setShownBoardManagements(items.label)
    }
  }

  const handleChosenManagement = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const items = allManagements.find(item => item.id === id)
    if (items && boardManagements.find(item => item.id === items.id)) {
      setBoardManagements(
        boardManagements.filter(item => item.id !== items.id)
      )
      setShownChosenManagement(items.label)
    } else if (items && !boardManagements.find(item => item.id === items.id)) {
      setBoardManagements(
        [
          ...boardManagements,
          { id: items.id, label: items.label, archived: items.archived }
        ]
      )
      setShownChosenManagement(items.label)
    }
  }

  const handleEditMacrotheme = (item: IMacrotheme): void => {
    setNewMacrotheme(item.label)
    setControlEdit(5)
    setControl(5)
    setReleaseEdtion(true)
  }

  const handleAddMacrotheme = (): void => {
    clearPageVariables()
    setControl(5)
    setControlEdit(0)
    setReleaseEdtion(true)
  }

  const handleNewMacrotheme = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewMacrotheme(event.target.value)
  }

  const handleEditableMacrotheme = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewMacrotheme(event.target.value)
  }

  const handleEditCategory = (item: IWorkCategory): void => {
    setNewCategory(item.label)
    setControlEdit(3)
    setControl(3)
    setReleaseEdtion(true)
  }

  const handleAddCategory = (): void => {
    clearPageVariables()
    setControl(3)
    setControlEdit(0)
    setReleaseEdtion(true)
  }

  const handleNewCategory = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewCategory(event.target.value)
  }

  const handleEditableCategory = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewCategory(event.target.value)
  }

  const handleExistingCategories = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const items = allCategories.find(item => item.id === id)
    if (items && macrothemeCategories.find(item => item.id === items.id)) {
      setMacrothemeCategories(
        macrothemeCategories.filter(item => item.id !== items.id)
      )
      setShownMacrothemeCategories(items.label)
    } else if (items && !macrothemeCategories.find(item => item.id === items.id)) {
      setMacrothemeCategories(
        [
          ...macrothemeCategories,
          { id: items.id, label: items.label, archived: items.archived }
        ]
      )
      setShownMacrothemeCategories(items.label)
    }
  }

  const handleChosenCategory = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const items = allCategories.find(item => item.id === id)
    if (items && macrothemeCategories.find(item => item.id === items.id)) {
      setMacrothemeCategories(
        macrothemeCategories.filter(item => item.id !== items.id)
      )
      setShownChosenCategory(items.label)
    } else if (items && !macrothemeCategories.find(item => item.id === items.id)) {
      setMacrothemeCategories(
        [
          ...macrothemeCategories,
          { id: items.id, label: items.label, archived: items.archived }
        ]
      )
      setShownChosenCategory(items.label)
    }
  }

  const handleExistingModel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const items = allModels.find(item => item.id === id)
    if (items && categoryModels.find(item => item.id === items.id)) {
      setCategoryModels(
        categoryModels.filter(item => item.id !== items.id)
      )
      setShownCategoryModels(items.label)
    } else if (items && !categoryModels.find(item => item.id === items.id)) {
      setCategoryModels(
        [
          ...categoryModels,
          { id: items.id, label: items.label, archived: items.archived }
        ]
      )
      setShownCategoryModels(items.label)
    }
  }

  const handleChosenModel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const items = allModels.find(item => item.id === id)
    if (items && categoryModels.find(item => item.id === items.id)) {
      setCategoryModels(
        categoryModels.filter(item => item.id !== items.id)
      )
      setShownChosenModel(items.label)
    } else if (items && !categoryModels.find(item => item.id === items.id)) {
      setCategoryModels(
        [
          ...categoryModels,
          { id: items.id, label: items.label, archived: items.archived }
        ]
      )
      setShownChosenModel(items.label)
    }
  }

  // ----------------- QUESTIONS ------------------------

  const handleEditQuestion = (item: IWorkQuestion): void => {
    setNewQuestion(item.title)
    setNewQuestionDescription(item.question)
    setNewQuestionWeight(item.weight)
    setCreateNewQuestion(false)
    setEditQuestion(true)
  }

  const handleNewQuestion = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewQuestion(event.target.value)
  }

  const handleEditableQuestion = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewQuestion(event.target.value)
  }

  const handleNewQuestionDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewQuestionDescription(event.target.value)
  }

  const handleEditableQuestionDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewQuestionDescription(event.target.value)
  }

  const handleEditableQuestionWeight = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewQuestionWeight(Number(event.target.value))
  }

  const handleNewQuestionWeight = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewQuestionWeight(Number(event.target.value))
  }

  const handleAddQuestion = (): void => {
    setEditQuestion(false)
    setCreateNewQuestion(true)
  }

  const handleExistingQuestionModels = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const items = allModels.find(item => item.id === id)
    if (items && questionModels.find(item => item.id === items.id)) {
      setQuestionModels(
        questionModels.filter(item => item.id !== items.id)
      )
      setShownQuestionModels(items.label)
    } else if (items && !questionModels.find(item => item.id === items.id)) {
      setQuestionModels(
        [
          ...questionModels,
          { id: items.id, label: items.label, archived: items.archived }
        ]
      )
      setShownQuestionModels(items.label)
    }
  }

  const handleChosenQuestionModel = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const id = Number(e.target.value)
    const items = allModels.find(item => item.id === id)
    if (items && questionModels.find(item => item.id === items.id)) {
      setQuestionModels(
        questionModels.filter(item => item.id !== items.id)
      )
      setShownChosenQuestionModel(items.label)
    } else if (items && !questionModels.find(item => item.id === items.id)) {
      setQuestionModels(
        [
          ...questionModels,
          { id: items.id, label: items.label, archived: items.archived }
        ]
      )
      setShownChosenQuestionModel(items.label)
    }
  }

  // ------------------ END QUESTIONS---------------------

  const handleEditModel = (item: IWorkModel): void => {
    setNewModel(item.label)
    setControlEdit(4)
    setControl(4)
    setReleaseEdtion(true)
  }

  const handleAddModel = (): void => {
    clearPageVariables()
    setControl(4)
    setControlEdit(0)
    setReleaseEdtion(true)
  }

  const handleNewModel = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewModel(event.target.value)
  }

  const handleEditableModel = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewModel(event.target.value)
  }

  const handleSaveChanges = async (): Promise<void> => {

    switch (control) {
      case 1:
        if (newBoard !== '' && newBoard !== null) {

          if (newBoard.length < 2) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A diretoria deve conter no mínimo 2 caracteres',
              type: 'error'
            }))

            return
          }

          if (newBoard.length > 255) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A diretoria não deve conter mais que 255 caracteres',
              type: 'error'
            }))

            return
          }

          /* if (!newBoard.match(/^[\p{L}0-9 ?!,.:;'-]+$/u)) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A diretoria não deve conter alguns caracteres especiais',
              type: 'error'
            }))

            return
          } */
        } else {
          dispatch(setOpenGlobalMessageAction({
            message: 'Digite uma nova Diretoria para prosseguir',
            type: 'error'
          }))
          return
        }
        if (controlEdit === 1) {
          if (!board) return
          const ids = boardManagements.map(item => item.id)
          const dataBoard = {
            label: newBoard,
            managementsId: boardManagements.length > 0 ? ids : []
          }
          const responseBoard = await backEnd('PUT', `boards/${board.id}`, dataBoard)

          if (!responseBoard.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseBoard.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Diretoria editada com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        } else {
          const dataBoard = {
            label: newBoard,
            managementsId: boardManagements.length > 0 ? boardManagements.map(item => item.id) : []
          }
          const responseBoard = await backEnd('POST', 'boards', dataBoard)

          if (!responseBoard.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseBoard.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Diretoria criada com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        }
        break
      case 2:
        if (newManagement !== '' && newManagement !== null) {

          if (newManagement.length < 2) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A gerência deve conter no mínimo 2 caracteres',
              type: 'error'
            }))

            return
          }

          if (newManagement.length > 255) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A gerência não deve conter mais que 255 caracteres',
              type: 'error'
            }))

            return
          }

          /* if (!newManagement.match(/^[\p{L}0-9 ?!,.:;'-]+$/u)) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A gerência não deve conter alguns caracteres especiais',
              type: 'error'
            }))

            return
          } */
        } else {
          dispatch(setOpenGlobalMessageAction({
            message: 'Digite uma nova gerência para prosseguir',
            type: 'error'
          }))
          return
        }

        if (controlEdit === 2) {
          if (!management) return
          const dataManagement = {
            label: newManagement
          }
          const responseManagement = await backEnd('PUT', `managements/${management.id}`, dataManagement)

          if (!responseManagement.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseManagement.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Gerência editada com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        } else {
          const dataManagement = {
            label: newManagement
          }
          const responseManagement = await backEnd('POST', 'managements', dataManagement)

          if (!responseManagement.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseManagement.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Gerência criada com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        }
        break
      case 3:
        if (newCategory !== '' && newCategory !== null) {

          if (newCategory.length < 2) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A categoria deve conter no mínimo 2 caracteres',
              type: 'error'
            }))

            return
          }

          if (newCategory.length > 255) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A categoria não deve conter mais que 255 caracteres',
              type: 'error'
            }))

            return
          }

          /* if (!newCategory.match(/^[\p{L}0-9 ?!,.:;'-]+$/u)) {
            dispatch(setOpenGlobalMessageAction({
              message: 'A categoria não deve conter alguns caracteres especiais',
              type: 'error'
            }))

            return
          } */
        } else {
          dispatch(setOpenGlobalMessageAction({
            message: 'Digite uma nova categoria para prosseguir',
            type: 'error'
          }))
          return
        }

        if (controlEdit === 3) {
          if (!category) return
          const dataCategory = {
            label: newCategory,
            modelsId: categoryModels.length > 0 ? categoryModels.map(item => item.id) : []
          }
          const responseCategory = await backEnd('PUT', `works/categories/${category.id}`, dataCategory)

          if (!responseCategory.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseCategory.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Categoria editada com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        } else {
          const dataCategory = {
            label: newCategory,
            modelsId: categoryModels.length > 0 ? categoryModels.map(item => item.id) : []
          }
          const responseCategory = await backEnd('POST', 'works/categories', dataCategory)

          if (!responseCategory.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseCategory.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Categoria criada com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        }
        break
      case 4:
        if (newModel !== '' && newModel !== null) {

          if (newModel.length < 2) {
            dispatch(setOpenGlobalMessageAction({
              message: 'O modelo deve conter no mínimo 2 caracteres',
              type: 'error'
            }))

            return
          }

          if (newModel.length > 255) {
            dispatch(setOpenGlobalMessageAction({
              message: 'O modelo não deve conter mais que 255 caracteres',
              type: 'error'
            }))

            return
          }

          /* if (!newModel.match(/^[\p{L}0-9 ?!,.:;'-]+$/u)) {
            dispatch(setOpenGlobalMessageAction({
              message: 'O modelo não deve conter alguns caracteres especiais',
              type: 'error'
            }))

            return
          } */
        } else {
          dispatch(setOpenGlobalMessageAction({
            message: 'Digite um novo modelo para prosseguir',
            type: 'error'
          }))
          return
        }

        if (controlEdit === 4) {
          if (!model) return
          const dataModel = {
            label: newModel
          }
          const responseModel = await backEnd('PUT', `works/models/${model.id}`, dataModel)

          if (!responseModel.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseModel.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Modelo/Ferramenta editado com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          // validações questões
          if (createNewQuestion || editQuestion) {
            if (newQuestion !== '' && newQuestion !== null) {

              if (newQuestion.length < 2) {
                dispatch(setOpenGlobalMessageAction({
                  message: 'O título da questão deve conter no mínimo 2 caracteres',
                  type: 'error'
                }))

                return
              }
            } else {
              dispatch(setOpenGlobalMessageAction({
                message: 'Digite um novo título da questão para prosseguir',
                type: 'error'
              }))
              return
            }

            if (newQuestionDescription !== '' && newQuestionDescription !== null) {

              if (newQuestionDescription.length < 2) {
                dispatch(setOpenGlobalMessageAction({
                  message: 'A questão deve conter no mínimo 2 caracteres',
                  type: 'error'
                }))

                return
              }
            } else {
              dispatch(setOpenGlobalMessageAction({
                message: 'Digite uma nova questão para prosseguir',
                type: 'error'
              }))
              return
            }

            if (newQuestionWeight < 1 || newQuestionWeight === null) {
              dispatch(setOpenGlobalMessageAction({
                message: 'Defina um peso para prosseguir',
                type: 'error'
              }))
              return
            }

            if (editQuestion) {
              if (!question) return
              const dataQuestion = {
                title: newQuestion,
                question: newQuestionDescription,
                weight: newQuestionWeight,
                modelIds: questionModels.length > 0 ? questionModels.map(item => item.id) : []
              }
              const responseQuestion = await backEnd('PUT', `works/models/question/${question.id}`, dataQuestion)

              if (!responseQuestion.ok) {
                dispatch(setOpenGlobalMessageAction({
                  message: responseQuestion.msg
                }))
              } else {
                dispatch(setOpenGlobalMessageAction({
                  message: 'Questão editada com sucesso!',
                  type: 'success',
                  modal: {
                    title: 'Sucesso!'
                  }
                }))
              }
            }
            if (createNewQuestion) {
              const ids = questionModels.map(item => item.id)
              ids.push(selectedModelForQuestion)
              const dataQuestion = {
                title: newQuestion,
                question: newQuestionDescription,
                weight: newQuestionWeight,
                modelIds: questionModels.length > 0 ? ids : [selectedModelForQuestion]
              }
              const responseQuestion = await backEnd('POST', 'works/models/question', dataQuestion)

              if (!responseQuestion.ok) {
                dispatch(setOpenGlobalMessageAction({
                  message: responseQuestion.msg
                }))
              } else {
                dispatch(setOpenGlobalMessageAction({
                  message: 'Questão criada com sucesso!',
                  type: 'success',
                  modal: {
                    title: 'Sucesso!'
                  }
                }))
              }
            }
          }
          setGet(true)
          // clearPageVariables()
          clearQuestionVariables()
        } else {
          const dataModel = {
            label: newModel
          }
          const responseModel = await backEnd('POST', 'works/models', dataModel)

          if (!responseModel.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseModel.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Modelo/Ferramenta criado com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        }
        break
        case 5:
        if (newMacrotheme !== '' && newMacrotheme !== null) {

          if (newMacrotheme.length < 2) {
            dispatch(setOpenGlobalMessageAction({
              message: 'O macrotema deve conter no mínimo 2 caracteres',
              type: 'error'
            }))

            return
          }

          if (newMacrotheme.length > 255) {
            dispatch(setOpenGlobalMessageAction({
              message: 'O macrotema não deve conter mais que 255 caracteres',
              type: 'error'
            }))

            return
          }

          /* if (!newMacrotheme.match(/^[\p{L}0-9 ?!,.:;'-]+$/u)) {
            dispatch(setOpenGlobalMessageAction({
              message: 'O macrotema não deve conter alguns caracteres especiais',
              type: 'error'
            }))

            return
          } */
        } else {
          dispatch(setOpenGlobalMessageAction({
            message: 'Digite um novo macrotema para prosseguir',
            type: 'error'
          }))
          return
        }
        if (controlEdit === 5) {
          if (!macrotheme) return
          const dataMacrotheme = {
            label: newMacrotheme,
            categoriesId: macrothemeCategories.length > 0 ? macrothemeCategories.map(item => item.id) : []
          }
          const responseMacrotheme = await backEnd('PUT', `work-macrothemes/${macrotheme.id}`, dataMacrotheme)

          if (!responseMacrotheme.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseMacrotheme.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Macrotema editado com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        } else {
          const dataMacrotheme = {
            label: newMacrotheme,
            categoriesId: macrothemeCategories.length > 0 ? macrothemeCategories.map(item => item.id) : []
          }
          const responseMacrotheme = await backEnd('POST', 'work-macrothemes', dataMacrotheme)

          if (!responseMacrotheme.ok) {
            dispatch(setOpenGlobalMessageAction({
              message: responseMacrotheme.msg
            }))
          } else {
            dispatch(setOpenGlobalMessageAction({
              message: 'Macrotema criado com sucesso!',
              type: 'success',
              modal: {
                title: 'Sucesso!'
              }
            }))
            setGet(true)
          }
          clearPageVariables()
        }
        break
      default:
        console.log('Finalizando edição')
    }
  }

  const willDelete = (item: IBoard | IManagement | IWorkCategory | IWorkModel | IMacrotheme | IWorkQuestion, num: number): void => {
    setDeleteItem(item)
    setOpenDeleteModal(true)
    setControlDelete(num)
  }

  const handleCloseDeleteModal = (): void => {
    setOpenDeleteModal(false)
    clearPageVariables()
  }

  const handleDelete = async (): Promise<void> => {

    if (!event) return
    if (!deleteItem) return

    const data = {
      archived: true
    }

    if (controlDelete === 1) {

      setLoading(true)
      const response = await backEnd('PUT', `boards/${deleteItem.id}`, data)
      setLoading(false)
    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Diretoria excluída com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))
    setGet(true)
    clearPageVariables()
    } else if (controlDelete === 2) {

      setLoading(true)
      const response = await backEnd('PUT', `managements/${deleteItem.id}`, data)
      setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Gerência excluída com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))
    setGet(true)
    clearPageVariables()
    } else if (controlDelete === 3) {

      setLoading(true)
      const response = await backEnd('PUT', `works/categories/${deleteItem.id}`, data)
      setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Categoria excluída com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))
    setGet(true)
    clearPageVariables()
    } else if (controlDelete === 4) {

      setLoading(true)
      const response = await backEnd('PUT', `works/models/${deleteItem.id}`, data)
      setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Modelo/Ferramenta excluído com sucesso.',
        type: 'success',
        modal: {
          title: 'Sucesso!'
        }
      }))
      setGet(true)
      clearPageVariables()
    } else if (controlDelete === 5) {

      setLoading(true)
      const response = await backEnd('PUT', `work-macrothemes/${deleteItem.id}`, data)
      setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Macrotema excluído com sucesso.',
      type: 'success',
      modal: {
        title: 'Sucesso!'
      }
    }))
    setGet(true)
    clearPageVariables()
    } else if (controlDelete === 6) {

      setLoading(true)
      const response = await backEnd('DELETE', `works/models/question/${deleteItem.id}`)
      setLoading(false)

    if (!response.ok) {
      dispatch(setOpenGlobalMessageAction({
        message: response.msg
      }))
      return
    }

    dispatch(setOpenGlobalMessageAction({
      message: 'Questão excluída com sucesso.',
        type: 'success',
        modal: {
          title: 'Sucesso!'
        }
      }))
      setGet(true)
      clearQuestionVariables()
    }

    setOpenDeleteModal(false)
    setDeleteItem(null)
    setControlDelete(0)
  }

  const handleOpenNoticeModal = (): void => {
    setReleaseEdtion(false)
    setOpenNoticeModal(true)
  }

  const handleCloseNoticeModal = (): void => {
    setOpenNoticeModal(false)
  }

  const isQuestion = (item: any): item is IWorkQuestion => (item as IWorkQuestion) !== undefined

  return (
    <S.Container>
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
      >
        {
          isQuestion(deleteItem)
          ? (
            <>
              <p style={{ textAlign: 'center' }}>Deseja mesmo excluir a questão <strong>{deleteItem?.title}</strong>?</p>
              {
                deleteItem?.modelIds.length > 1 && (
                  <S.DeleteQuestionModalBox>
                    <p><em>IMPORTANTE!</em> Esta questão está vinculada também ao{questionModels.length > 1 ? 's' : ''} seguinte{questionModels.length > 1 ? 's' : ''} modelo{questionModels.length > 1 ? 's' : ''}:</p>
                      {
                        questionModels.map((item, index) => (
                          <S.ModelsList key={index}>
                            <li><strong>{item.label}</strong></li>
                          </S.ModelsList>
                        ))
                      }
                      <p>A questão será apagada de todos eles.</p>
                  </S.DeleteQuestionModalBox>
                )
              }
            </>
          )
          : (
            <p style={{ textAlign: 'center' }}>Deseja mesmo excluir {controlDelete === 1 ? 'a Diretoria' : controlDelete === 2 ? 'a Gerência' : controlDelete === 3 ? 'a Categoria' : controlDelete === 4 ? 'o modelo/ferramenta' : 'o macrotema'} <strong>{deleteItem?.label}</strong>?</p>
          )
        }
        <DialogContent>
          <div>
            {
              isQuestion(deleteItem)
            ? (
              <p>Esta ação não poderá ser desfeita. Deseja prosseguir?</p>
            )
            : (
              <p>Se este ítem estiver sendo utilizado dentro do sistema, ele será arquivado e não poderá mais ser associado aos demais itens. Caso este item não esteja sendo utilizado dentro do sistema, ele será excluído permanentemente. Em ambos os casos, esta ação não poderá ser desfeita. Deseja prosseguir?</p>
            )
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteItem !== null ? async () => { await handleDelete() } : handleCloseDeleteModal}>Excluir</Button>
          <Button onClick={handleCloseDeleteModal} autoFocus>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openNoticeModal}
        onClose={handleCloseNoticeModal}
      >
        <DialogContent>
          <div>
              Selecione um ítem para continuar
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoticeModal} autoFocus>Fechar</Button>
        </DialogActions>
      </Dialog>
      <S.ScreenContainer>
        {
          loading
            ? (
              <S.LoadingContainer>
                <CircularProgress />
              </S.LoadingContainer>
            )
            : (
              <S.ColumnContainer width={100}>
              <h2>Editar Conteúdo</h2>
              <p>Clique nos botões de adicionar, editar ou deletar de um dos itens abaixo para alterá-lo:</p>
                <S.RowContainer className='sp-bt top'>
                  <S.SelectsContainer>
                    <S.ColumnContainer width={45}>
                      <S.RowContainer>
                        <Button onClick={() => { handleAddBoard() }}><AddCircleOutlineIcon className='icon' /></Button>
                        <TextField className='select' fullWidth size='small' select id='board' name='board' value={shownBoard} onChange={handleSelectBoard} label='Diretoria'>
                          <MenuItem value={shownBoard} style={{ display: 'none' }} disabled>{shownBoard}</MenuItem>
                          {
                            filteredBoards.map((item, index) => (
                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                            ))
                          }
                        </TextField>
                        <Button onClick={() => { board !== null ? handleEditBoard(board) : handleOpenNoticeModal() }}><ModeOutlinedIcon className='icon' /></Button>
                        <Button onClick={() => { board !== null ? willDelete(board, 1) : handleOpenNoticeModal() }}><DeleteForeverOutlinedIcon className='icon' /></Button>
                      </S.RowContainer>
                      <S.RowContainer>
                        <Button onClick={() => { handleAddCategory() }}><AddCircleOutlineIcon className='icon' /></Button>
                        <TextField className='select' fullWidth size='small' select id='category' name='category' value={shownCategory} onChange={handleSelectCategory} label='Categoria'>
                          <MenuItem value={shownCategory} style={{ display: 'none' }} disabled>{shownCategory}</MenuItem>
                          {
                            filteredCategories.map((item, index) => (
                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                            ))
                          }
                        </TextField>
                        <Button onClick={() => { category !== null ? handleEditCategory(category) : handleOpenNoticeModal() }}><ModeOutlinedIcon className='icon' /></Button>
                        <Button onClick={() => { category !== null ? willDelete(category, 3) : handleCloseDeleteModal() }}><DeleteForeverOutlinedIcon className='icon' /></Button>
                      </S.RowContainer>
                      <S.RowContainer>
                        <Button onClick={() => { handleAddMacrotheme() }}><AddCircleOutlineIcon className='icon' /></Button>
                        <TextField className='select' fullWidth size='small' select id='macrotheme' name='macrotheme' value={shownMacrotheme} onChange={handleSelectMacrotheme} label='Macrotema'>
                          <MenuItem value={shownMacrotheme} style={{ display: 'none' }} disabled>{shownMacrotheme}</MenuItem>
                          {
                            filteredMacrothemes.map((item, index) => (
                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                            ))
                          }
                        </TextField>
                        <Button onClick={() => { macrotheme !== null ? handleEditMacrotheme(macrotheme) : handleOpenNoticeModal() }}><ModeOutlinedIcon className='icon' /></Button>
                        <Button onClick={() => { macrotheme !== null ? willDelete(macrotheme, 5) : handleOpenNoticeModal() }}><DeleteForeverOutlinedIcon className='icon' /></Button>
                      </S.RowContainer>
                    </S.ColumnContainer>
                    <S.ColumnContainer width={45}>
                      <S.RowContainer>
                        <Button onClick={() => { handleAddManagement() }}><AddCircleOutlineIcon className='icon' /></Button>
                        <TextField className='select' fullWidth size='small' select id='management' name='management' value={shownManagement} onChange={handleSelectManagement} label='Gerência'>
                          <MenuItem value={shownManagement} style={{ display: 'none' }} disabled>{shownManagement}</MenuItem>
                          {
                            filteredManagements.map((item, index) => (
                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                            ))
                          }
                        </TextField>
                        <Button onClick={() => { management !== null ? handleEditManagement(management) : handleOpenNoticeModal() }}><ModeOutlinedIcon className='icon' /></Button>
                        <Button onClick={() => { management !== null ? willDelete(management, 2) : handleCloseDeleteModal() }}><DeleteForeverOutlinedIcon className='icon' /></Button>
                      </S.RowContainer>
                      <S.RowContainer>
                        <Button onClick={() => { handleAddModel() }}><AddCircleOutlineIcon className='icon' /></Button>
                        <TextField className='select' fullWidth size='small' select id='modelTool' name='modelTool' value={shownModel} onChange={handleSelectModel} label='Modelo / Ferramenta'>
                          <MenuItem value={shownModel} style={{ display: 'none' }} disabled>{shownModel}</MenuItem>
                          {
                            filteredModels.map((item, index) => (
                              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                            ))
                          }
                        </TextField>
                        <Button onClick={() => { model !== null ? handleEditModel(model) : handleOpenNoticeModal() }}><ModeOutlinedIcon className='icon' /></Button>
                        <Button onClick={() => { model !== null ? willDelete(model, 4) : handleCloseDeleteModal() }}><DeleteForeverOutlinedIcon className='icon' /></Button>
                      </S.RowContainer>
                    </S.ColumnContainer>
                  </S.SelectsContainer>
                </S.RowContainer>
                <S.RowContainer className='container-edicao'>
                  {
                    releaseEdtion && (
                      <S.ColumnContainer width={80}>
                      {
                          control === 1 && (
                            <>
                              {
                                controlEdit === 1 && (
                                  <>
                                    <TextField required size='small' id='newBoard' name='newBoard' value={newBoard} label='Diretoria' onChange={handleEditableBoard}/>
                                    <p>Esta diretoria possui a{shownBoardManagements.length !== 1 ? 's' : ''} gerência{shownBoardManagements.length !== 1 ? 's' : ''} abaixo vinculada{shownBoardManagements.length !== 1 ? 's' : ''}. Clique na caixa de seleção para adicionar ou remover gerências</p>
                                    <TextField className='select' fullWidth size='small' select id='existingManagement' name='existingManagement' value={shownManagement} onChange={handleExistingManagement} label='Gerência'>
                                      <MenuItem value={shownManagement} style={{ display: 'none' }} disabled>{shownManagement}</MenuItem>
                                      {
                                        filteredManagements.map((item, index) => (
                                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                    {
                                      boardManagements.map((item, index) => (
                                        <p className='items-list' key={index}>{item.label}</p>
                                      ))
                                    }
                                  </>
                                )
                              }
                              {
                                controlEdit === 0 && (
                                  <>
                                    <TextField required size='small' id='newBoard' name='newBoard' defaultValue={newBoard} label='Nova diretoria' onChange={handleNewBoard}/>
                                    <p>Clique na caixa de seleção para vincular ou remover gerências à nova diretoria</p>
                                    <TextField className='select' fullWidth size='small' select id='chosenManagement' name='chosenManagement' value={shownChosenManagement} disabled={allManagements.length === 0} onChange={handleChosenManagement} label='Gerência'>
                                      <MenuItem value={shownChosenManagement} style={{ display: 'none' }} disabled>{shownChosenManagement}</MenuItem>
                                      {
                                        filteredManagements.map((item, index) => (
                                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                    {
                                      boardManagements.map((item, index) => (
                                        <p className='items-list' key={index}>{item.label}</p>
                                      ))
                                    }
                                  </>
                                )
                              }
                            </>
                          )
                        }
                        {
                          control === 2 && (
                            <>
                              {
                                controlEdit === 2 && (
                                  <TextField required size='small' id='newManagement' name='newManagement' value={newManagement} label='Gerência' onChange={handleEditableManagement}/>
                                )
                              }
                              {
                                controlEdit === 0 && (
                                  <TextField required size='small' id='newManagement' name='newManagement' defaultValue={newManagement} label='Nova gerência' onChange={handleNewManagement}/>
                                )
                              }
                            </>
                          )
                        }
                        {
                          control === 3 && (
                            <>
                              {
                                controlEdit === 3 && (
                                  <>
                                    <TextField required size='small' id='newCategory' name='newCategory' value={newCategory} label='Categoria' onChange={handleEditableCategory}/>
                                    <p>Esta categoria possui a{shownCategoryModels.length !== 1 ? 's' : ''} modelo{shownCategoryModels.length !== 1 ? 's' : ''}/ferramenta{shownCategoryModels.length !== 1 ? 's' : ''} abaixo vinculada{shownCategoryModels.length !== 1 ? 's' : ''}. Clique na caixa de seleção para adicionar ou remover modelo/ferramentas</p>
                                    <TextField className='select' fullWidth size='small' select id='existingModel' name='existingModel' value={shownModel} onChange={handleExistingModel} label='Modelo/Ferramenta'>
                                      <MenuItem value={shownModel} style={{ display: 'none' }} disabled>{shownModel}</MenuItem>
                                      {
                                        filteredModels.map((item, index) => (
                                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                    {
                                      categoryModels.map((item, index) => (
                                        <p className='items-list' key={index}>{item.label}</p>
                                      ))
                                    }
                                  </>
                                )
                              }
                              {
                                controlEdit === 0 && (
                                  <>
                                  <TextField required size='small' id='newCategory' name='newCategory' defaultValue={newCategory} label='Nova categoria' onChange={handleNewCategory}/>
                                    <p>Clique na caixa de seleção para vincular ou remover modelos/ferramentas à nova categoria</p>
                                    <TextField className='select' fullWidth size='small' select id='chosenModel' name='chosenModel' value={shownChosenModel} onChange={handleChosenModel} label='Modelo/Ferramenta'>
                                      <MenuItem value={shownChosenModel} style={{ display: 'none' }} disabled>{shownChosenModel}</MenuItem>
                                      {
                                        filteredModels.map((item, index) => (
                                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                    {
                                      categoryModels.map((item, index) => (
                                        <p className='items-list' key={index}>{item.label}</p>
                                      ))
                                    }
                                  </>
                                )
                              }
                            </>
                          )
                        }
                        {
                          control === 4 && (
                            <>
                              {
                                controlEdit === 4 && (
                                  <>
                                    <TextField required size='small' id='newModel' name='newModel' value={newModel} label='Modelo/ferramenta' onChange={handleEditableModel}/>
                                    <h5>Gerenciar questões de avaliação vinculados a esse modelo:</h5>
                                  </>
                                )
                              }
                              {
                                !editQuestion && !createNewQuestion && controlEdit === 4 && (
                                  <>
                                    <S.RowContainer>
                                      <Button onClick={() => { handleAddQuestion() }}><AddCircleOutlineIcon className='icon' /></Button>
                                      <TextField className='select' fullWidth size='small' select id='question' name='question' value={shownQuestion} onChange={handleSelectQuestion} label='Questão'>
                                        <MenuItem value={shownQuestion} style={{ display: 'none' }} disabled>{shownQuestion}</MenuItem>
                                        {
                                          modelQuestions.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                                          ))
                                        }
                                      </TextField>
                                      <Button onClick={() => { question !== null ? handleEditQuestion(question) : handleOpenNoticeModal() }}><ModeOutlinedIcon className='icon' /></Button>
                                      <Button onClick={() => { question !== null ? willDelete(question, 6) : handleOpenNoticeModal() }}><DeleteForeverOutlinedIcon className='icon' /></Button>
                                    </S.RowContainer>
                                  </>
                                )
                              }
                              {
                                editQuestion && (
                                  <>
                                    <TextField className='inputMargin' required size='small' id='newQuestion' name='newQuestion' value={newQuestion} label='Título' onChange={handleEditableQuestion}/>
                                    <TextField className='inputMargin' required size='small' id='newQuestionDescription' name='newQuestionDescription' value={newQuestionDescription} label='Questão' onChange={handleEditableQuestionDescription}/>
                                    <TextField className='inputMargin' type='number' required size='small' id='newQuestionWeight' name='newQuestionWeight' value={newQuestionWeight} label='Peso' onChange={handleEditableQuestionWeight}/>
                                    <p>Esta questão está vinculada ao{shownQuestionModels.length !== 1 ? 's' : ''} seguinte{shownQuestionModels.length !== 1 ? 's' : ''} Modelo{shownQuestionModels.length !== 1 ? 's' : ''}. Clique na caixa de seleção para adicionar ou remover modelos</p>
                                    <TextField className='select' fullWidth size='small' select id='existingQuestionModels' name='existingQuestionModels' value={shownQuestionModels} onChange={handleExistingQuestionModels} label='Modelo/Ferramenta'>
                                      <MenuItem value={shownQuestionModels} style={{ display: 'none' }} disabled>{shownQuestionModels}</MenuItem>
                                      {
                                        filteredModels.map((item, index) => (
                                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                    {
                                      questionModels.map((item, index) => (
                                        <p className='items-list' key={index}>{item.label}</p>
                                      ))
                                    }
                                  </>
                                )
                              }
                              {
                                createNewQuestion && (
                                  <>
                                    <TextField className='inputMargin' required size='small' id='newQuestion' name='newQuestion' defaultValue={newQuestion} label='Novo título' onChange={handleNewQuestion}/>
                                    <TextField className='inputMargin' required size='small' id='newQuestionDescription' name='newQuestionDescription' defaultValue={newQuestionDescription} label='Nova questão' onChange={handleNewQuestionDescription}/>
                                    <TextField className='inputMargin' type='number' required size='small' id='newQuestionWeight' name='newQuestionWeight' defaultValue={newQuestionWeight} label='Peso' onChange={handleNewQuestionWeight}/>
                                    <p>Clique na caixa de seleção para vincular ou remover outros modelos à nova questão</p>
                                    <TextField className='select' fullWidth size='small' select id='chosenQuestionModel' name='chosenQuestionModel' value={shownChosenQuestionModel} disabled={allModels.length === 0} onChange={handleChosenQuestionModel} label='Modelo/Ferramenta'>
                                      <MenuItem value={shownChosenQuestionModel} style={{ display: 'none' }} disabled>{shownChosenQuestionModel}</MenuItem>
                                      {
                                        filteredModels.filter(item => item.id !== selectedModelForQuestion).map((item, index) => (
                                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                    {
                                      questionModels.map((item, index) => (
                                        <p className='items-list' key={index}>{item.label}</p>
                                      ))
                                    }
                                  </>
                                )
                              }
                              {
                                controlEdit === 0 && (
                                  <TextField required size='small' id='newModel' name='newModel' defaultValue={newModel} label='Novo modelo/ferramenta' onChange={handleNewModel}/>
                                )
                              }
                            </>
                          )
                        }
                        {
                          control === 5 && (
                            <>
                              {
                                controlEdit === 5 && (
                                  <>
                                    <TextField size='small' id='newMacrotheme' name='newMacrotheme' value={newMacrotheme} label='Macrotema' onChange={handleEditableMacrotheme}/>
                                    <p>Este macrotema possui a{shownMacrothemeCategories.length !== 1 ? 's' : ''} categoria{shownMacrothemeCategories.length !== 1 ? 's' : ''} abaixo vinculada{shownMacrothemeCategories.length !== 1 ? 's' : ''}. Clique na caixa de seleção para adicionar ou remover categorias</p>
                                    <TextField className='select' fullWidth size='small' select id='existingCategory' name='existingCategory' value={shownCategory} onChange={handleExistingCategories} label='Categoria'>
                                      <MenuItem value={shownCategory} style={{ display: 'none' }} disabled>{shownCategory}</MenuItem>
                                      {
                                        filteredCategories.map((item, index) => (
                                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                    {
                                      macrothemeCategories.map((item, index) => (
                                        <p className='items-list' key={index}>{item.label}</p>
                                      ))
                                    }
                                  </>
                                )
                              }
                              {
                                controlEdit === 0 && (
                                  <>
                                    <TextField required size='small' id='newMacrotheme' name='newMacrotheme' defaultValue={newMacrotheme} label='Novo Macrotema' onChange={handleNewMacrotheme}/>
                                    <p>Clique na caixa de seleção para vincular ou remover categorias ao novo macrotema</p>
                                    <TextField className='select' fullWidth size='small' select id='chosenCategory' name='chosenCategory' value={shownChosenCategory} disabled={allCategories.length === 0} onChange={handleChosenCategory} label='Categoria'>
                                      <MenuItem value={shownChosenCategory} style={{ display: 'none' }} disabled>{shownChosenCategory}</MenuItem>
                                      {
                                        filteredCategories.map((item, index) => (
                                          <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                                        ))
                                      }
                                    </TextField>
                                    {
                                      macrothemeCategories.map((item, index) => (
                                        <p className='items-list' key={index}>{item.label}</p>
                                      ))
                                    }
                                  </>
                                )
                              }
                            </>
                          )
                        }
                        <S.RowContainer>
                          <Button onClick={() => { void handleSaveChanges() }}>Salvar</Button>
                          <Button onClick={() => { createNewQuestion || editQuestion ? clearQuestionVariables() : clearPageVariables() }}>Cancelar</Button>
                        </S.RowContainer>
                      </S.ColumnContainer>
                    )
                  }
                </S.RowContainer>
              </S.ColumnContainer>
            )
        }
      </S.ScreenContainer>
    </S.Container>
  )
}
