import styled from 'styled-components'
import { theme } from '../../config/theme'

interface IColumn {
  width?: number
}

const md = 1090

export const Container = styled.div`
  color: #ccc;
  padding: 10px;
  margin: 0;
  h2 {
    margin: 10px 0;
  }
`

export const DialogContent = styled.div`
  p {
      margin: 0;
      font-size: 14px;
    }
  align-items: start;
  justify-content: start;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`

export const DialogContentHeader = styled.div`
  align-items: start;
  justify-content: start;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-bottom: 25px;
`

export const DialogContentBody = styled.div<IColumn>`
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 4px;
  width: ${({ width }) => width ?? 80}%;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .css-nz481w-MuiInputBase-input-MuiInput-input {
    width: 50%;
    margin: auto;
  }
  .input {
    text-align: center;
  }
  label {
    font-size: 12px;
    color: #3b81f787;
  }
`

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: space-around;
  @media (max-width: ${md}px) {
    flex-direction: row;
    align-items: center;
    }
`

export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 40}%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px 15px 0;
  @media (max-width: ${md}px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
  }
  .notifcationParagrafy {
    margin: 0;
    p {
      text-align: justify;
      font-size: 13px;
    }
  }
  
`
export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p, h3, h5 {
    color: #000;
  }
  .button {
    margin: 30px 0;
    cursor: pointer;
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
`
export const ScreenContainer = styled.div`
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h2{
    color: ${theme.palette.secondary.main};
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
`
export const LinkStyle = styled.button`
  text-decoration: underline;
  color: ${theme.palette.primary.main};
  font-size: 16px;
  background-color: #fff;
  border: none;
  margin: 0 0 20px 0;
  cursor: pointer;
`
export const LoadingContainer = styled.div`
  align-self: center;
  margin: auto;
`
export const NotificationContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #3b81f766;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  h4 {
    margin: 2px 0;
    color: #222;
    text-align: left;
    word-wrap: break-word;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  p {
    margin: 0;
    font-size: 12px;
    color: #666;
  }
`
