import styled from 'styled-components'
// import { theme } from '../../config/theme'

interface IRow {
  justifycontent?: string
}

interface IColumn {
  width?: number
}

const sm = 440
const md = 880
const lg = 1090

export const Container = styled.div`
  color: #ccc;
  margin: 0;
  padding: 10px;  
`

export const ScreenContainer = styled.div`
 width: 90%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 h2, h5{
    color: rgba(94, 53, 177, 0.7);
  }
  @media (max-width: ${md}px) {
    flex-direction: column;
  }
  .sp-bt {
    justify-content: space-between;
  }
  .css-1m9pwf3 {
    border: 1px solid #000;
  }
  .top {
    margin-top: 15px;
  }
  p {
    color: #000;
  }
  span {
    color: #444;
  }
  .color {
    border-bottom: 1px solid #3b81f7;
    margin-bottom: 7px;
  }
  .container-edicao {
    justify-content: center;
    border-top: 1px solid #3b81f7;
    margin-top: 1rem;
    padding: 1rem;
  }
  .items-list {
    margin: .5rem;
    font-size: .9rem;
  }
`

export const SelectsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectEvaluatorContainer = styled.div`
  width: 70%;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  margin: 5px auto;
  p {
    color: #444;
  }
  .input {
    width: 60%;
  }
`

export const FormContainer = styled.div`
 width: 95%;
 display: flex;
 align-items: center;
 justify-content: space-around;
 flex-wrap: wrap;
 margin: 0 auto;
 margin-bottom: 20px;
 border-left: 1px solid #3b81f7;
 @media (max-width: ${md}px) {
  border-left-width: 0;
  width: 85%;
  }
 h4 {
  color: #000;
  text-align: center;
  @media (max-width: ${sm}px) {
    font-size: 12px;
  }
  @media (min-width: ${sm}px) and (max-width: ${lg}px) {
    font-size: 13px;
  }
 }
`
export const ColumnContainer = styled.div<IColumn>`
  width: ${({ width }) => width ?? 10}%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;
  .select {
    margin-bottom: 8px;
  }
`

export const RowContainer = styled.div<IRow>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifycontent }) => justifycontent ?? 'flex-start'};
  .inputMargin {
    margin: 0 0 15px 0;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  aspect-ratio: 2.5/1;
`
export const SelectFileBox = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // align-self: stretch;
  justify-content: center;
  // margin: 2px 0 30px 0;
  @media (max-width: ${md}px) {
    width: 45%;
  }
  /* div {
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      text-align: center;
    }
  } */
  input[type="file"] {
    display: none;
  }
  label {
    width: 60%;
    text-align: center;
    display: block;
    padding: 7px 5px;
    border-radius: 4px;
    cursor: pointer;
    color: #3b81f7;
    border: 1px solid #3b81f7;
    margin: 5px 0;
    font-size: 13px;
  }
  `
  export const ModelsList = styled.div`
    border-bottom: 1px solid #ccc;
    list-style: none;
    margin: 15px auto;
`

export const DeleteQuestionModalBox = styled.div`
    width: 85%;
    margin: 2px auto;
`
